<template >
	<div class="body">
		<navTop></navTop>
		<div class="wrap">
			<div class="wrap-left">
				<menuLeft :active="menuIndex"></menuLeft>
			</div>
			<div class="wrap-right">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm" hide-required-asterisk="" style="width:500px;">
					<el-form-item :label="$t('exchangeGift.giftName')" prop="giftName">
					  <el-input v-model="ruleForm.giftName"></el-input>
					</el-form-item>
					<el-form-item :label="$t('exchangeGift.giftType')" prop="giftType">
					  <el-input v-model="ruleForm.giftType"></el-input>
					</el-form-item>
					<el-form-item :label="$t('exchangeGift.defaultAddress')" prop="defaultAddress">
					  <el-input type="textarea" v-model="ruleForm.defaultAddress"></el-input>
					</el-form-item>
					<el-form-item>
					  <el-button type="primary" @click="submitForm('ruleForm')" style="width:100%;">
					    {{ $t('login.submit') }}
					  </el-button>
					</el-form-item>
				</el-form>
			 
			</div>
		</div>
	</div>	
</template>
<script>

import menuLeft from "@/components/MenuLeft";
import navTop from "@/components/navTop";
export default {
	components: {menuLeft,navTop},
	data() {
		return {
			menuIndex:0,
			ruleForm: {
				giftName: '',
				giftType:'',
				defaultAddress:'',
			},
			rules: {
				giftName: [
					{required: true, message: this.$t("exchangeGift.mesGiftName"), trigger: 'blur'},
					
				],
				giftType: [
					{required: true, message: this.$t("exchangeGift.mesGiftType"), trigger: 'blur'},
					
				],
				defaultAddress: [
					{required: true, message: this.$t("exchangeGift.mesDefaultAddress"), trigger: 'blur'},
					
				],
			 
			}
		}
	},
	methods: {
		submitForm(formName) {
		  this.$refs[formName].validate((valid) => {
		    if (valid) {
		      this.toSubmint();
		    } else {
		      console.log('error submit!!');
		      return false;
		    }
		  });
		},
	}
}
</script>
<style  scoped>
.body {
  background-color: #eee;
  width: 100%;
  height:1080px;
}

.wrap {
  margin: 0 auto;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.wrap-left {
  font-size: 14px;
  width: 20%;
  height: 800px;
  background-color: #fff;
  padding: 20px 15px 40px;
}

.wrap-right {
  width: 78%;
  height: 800px;
  background-color: #fff;
  padding:40px 0px;
}


</style>
